import angular from 'angular'
import { find } from 'lodash-es'

function controller($scope, $store) {
  const ctrl = this

  ctrl.store = $store
  ctrl.filters = $store.searchFilters
  ctrl.hardwareOrder = $store.hardwareOrder
  ctrl.customAccessory = {}

  const watchers = {
    selectedItem: (nV) => (ctrl.selectedItem = nV),
    // I can't use ctrl.filter.tab because
    // new device order have suspended lines mode :(
    device: (nV) => (ctrl.filters.products_category = nV ? 'replacement' : 'new'),
    deviceModelId: (nV) => (ctrl.filters.device_model_id = nV),
  }

  $scope.ctrl = ctrl
  $scope.$watch('ctrl.store.selectedItem', watchers.selectedItem)

  const selectedDevice = () => ctrl.selectedItem?.device
  $scope.$watch(selectedDevice, watchers.device)

  const selectedDeviceModelId = () => ctrl.selectedItem?.device_model_id
  $scope.$watch(selectedDeviceModelId, watchers.deviceModelId)

  const selectProduct = ($event) => {
    if ($event) $event.preventDefault()
    const link = $event?.target

    const product = JSON.parse($(link).parents('.product').find('script').html())
    const selectedTerm = find(product.plans, { description: link.innerText.trim() })

    return addProduct(product, selectedTerm)
  }

  const addProduct = (product, selectedTerm) => {
    requireCarrier(ctrl.filters.carrier_id)
    ctrl.selectedItem.setProduct(product, selectedTerm)

    return ctrl.store.selectModelAccessories(product)
  }

  const changeSelectedProduct = () => {
    clearSelectedProduct()
    return ctrl.filters.enableProductsMode()
  }

  const clearSelectedProduct = () => {
    ctrl.selectedItem.setProduct(null)
    return clearBlockedFilters()
  }

  const selectInventoryDevice = ($event) => {
    if ($event) $event.preventDefault()
    const button = $event?.target

    const inventory_device = JSON.parse(
      $(button).parents('.inventory_device').find('script').html()
    )

    requireCarrier(ctrl.filters.carrier_id)
    ctrl.selectedItem.setInventoryDevice(inventory_device)

    return ctrl.store.selectModelAccessories(inventory_device)
  }

  const changeSelectedInventoryDevice = () => {
    clearSelectedInventoryDevice()
    return ctrl.filters.enableInventoryDevicesMode()
  }

  const clearSelectedInventoryDevice = () => {
    ctrl.selectedItem.setInventoryDevice(null)
    return clearBlockedFilters()
  }

  const clearBlockedFilters = () => {
    ctrl.filters.device_model_id = null
    ctrl.filters.device_make_id = null
    return clearRequiredCarrierId()
  }

  const selectLine = ($event) => {
    if ($event) $event.preventDefault()
    const customUrl = $event?.target.value

    return $.getJSON(customUrl).then((data) => {
      selectDevice(data)
      $scope.$apply()
    })
  }

  const selectDevice = (device) => {
    requireCarrier(device.carrier_base_id)
    ctrl.selectedItem.setLine(device)

    return ctrl.store.selectMode(ctrl.selectedItem)
  }

  const changeSelectedDevice = () => {
    clearSelectedDevice()
    if (ctrl.filters.isNewDeviceOrderTab()) ctrl.filters.enableSuspendedLinesMode()
    if (ctrl.filters.isHardwareUpgradeTab()) ctrl.filters.enableDevicesMode()
  }

  const clearSelectedDevice = () => {
    ctrl.selectedItem.setLine(null)
    clearRequiredCarrierId()
  }

  const addCustomAccessory = () => {
    requireCarrier(ctrl.filters.carrier_id)
    ctrl.selectedItem.addAccessory(ctrl.customAccessory)
    ctrl.customAccessory = {}
  }

  const addCustomProduct = (product) => {
    if (product.colors) ctrl.selectedItem.color = product.colors[0]
    return addProduct(product, product.plans[0])
  }

  const addAccessory = ($event) => {
    if ($event) $event.preventDefault()
    const button = $event?.target

    const accessory = JSON.parse($(button).parents('.accessory').find('script').html())

    requireCarrier(ctrl.filters.carrier_id)
    ctrl.selectedItem.addAccessory(accessory)
  }

  const removeAccessoryById = (id) => {
    const accessory = find(ctrl.selectedItem.accessories, { id: id })
    return removeAccessory(accessory)
  }

  const removeAccessory = (accessory) => {
    ctrl.selectedItem.removeAccessory(accessory)
    return clearRequiredCarrierId()
  }

  const requireCarrier = (carrierId) => ctrl.store.requireCarrier(carrierId)

  const clearRequiredCarrierId = () => ctrl.store.clearRequiredCarrierId()

  ctrl.selectProduct = selectProduct
  ctrl.changeSelectedProduct = changeSelectedProduct

  ctrl.selectInventoryDevice = selectInventoryDevice
  ctrl.changeSelectedInventoryDevice = changeSelectedInventoryDevice

  ctrl.selectLine = selectLine
  ctrl.selectDevice = selectDevice
  ctrl.changeSelectedDevice = changeSelectedDevice

  ctrl.addCustomAccessory = addCustomAccessory
  ctrl.addCustomProduct = addCustomProduct

  ctrl.addAccessory = addAccessory
  ctrl.removeAccessory = removeAccessory
  ctrl.removeAccessoryById = removeAccessoryById

  return ctrl
}

controller.$inject = ['$scope', '$hwStore']

angular.module('argon.hardwareOrdering').controller('hwSelectedItemCtrl', controller)
