import angular from 'angular'
import { find, pull } from 'lodash-es'

// shared class for stores
class Store {
  constructor(hardwareOrder, searchFilters, $timeout) {
    this.hardwareOrder = hardwareOrder
    this.searchFilters = searchFilters
    this.selectedItem = null
    this.timeout = $timeout
  }

  setOrder(order) {
    this.hardwareOrder.update(order)
    this.searchFilters.customer_id = order.customer_id

    // disable filter only for existing orders
    if (order.id && order.ordered_items.length != 0) {
      this.searchFilters.required_carrier_id = this.hardwareOrder.carrier_id
    } else {
      this.searchFilters.carrier_id = this.hardwareOrder.carrier_id
    }

    return this.preselectItem()
  }

  preselectItem() {
    let item
    if (this.hardwareOrder.id) {
      if (this.hardwareOrder.isMixed()) {
        item = this.hardwareOrder.buildItem()
      }
    } else {
      if (this.hardwareOrder.ordered_items.length == 0) {
        item = this.hardwareOrder.buildFirstItem()
      }
    }
    if (!item) item = this.hardwareOrder.ordered_items[0]

    return this.selectItem(item)
  }

  // change mode and tab should be in controller really, I think...
  selectItem(item) {
    this.selectedItem = null

    // it's needed because client give term from server when angular need to have same object
    // for radiobutton.
    if (item.permitted_product) {
      item.term = find(item.permitted_product.plans, { description: item.term.description })
    }

    if (!item.tab) item.tab = item.category

    this.searchFilters.tab = item.tab

    this.selectMode(item)

    pull(this.hardwareOrder.ordered_items, item)

    // will clear device without timeout...
    // see selected item controller watchers
    return this.timeout(() => (this.selectedItem = item))
  }

  // model here can be product or inventory device
  // null for accessory only orders
  selectModelAccessories(model) {
    this.searchFilters.enableAccessoriesMode()

    if (model) {
      this.searchFilters.device_model_id = model.device_model_id
      this.searchFilters.device_make_id = model.device_make_id
    }
  }

  selectMode(item) {
    if (item.isReady()) {
      // because this items should be ready by default
      return this.selectModelAccessories(item.actualDeviceModel())
      // if order can have only one replacement item
      // we need to display devices table at the first.
    } else if (item.isHardwareUpgrade() && item.device == null) {
      return this.searchFilters.enableDevicesMode()
    } else {
      return this.preselectMode()
    }
  }

  preselectMode() {
    return (this.searchFilters.mode = this.hardwareOrder.defaultMode())
  }

  requireCarrier(carrierId) {
    this.hardwareOrder.carrier_id = carrierId
    this.searchFilters.required_carrier_id = carrierId
  }

  clearRequiredCarrierId() {
    if (this.hardwareOrder.id) return
    if (this.hardwareOrder.ordered_items.length > 1) return
    if (!this.selectedItem?.isBlank()) return

    this.searchFilters.required_carrier_id = null
  }

  basketIsBlank() {
    if (this.hardwareOrder.ordered_items.length > 0) return false
    return this.selectedItem?.isBlank()
  }

  selectNewItem() {
    // item will be added to ordered items here
    this.selectItem(this.hardwareOrder.buildItem())

    this.searchFilters.device_model_id = null
    this.searchFilters.device_make_id = null
  }

  addToBasket(orderedItem) {
    // for future editing.
    orderedItem.tab = this.searchFilters.tab
    orderedItem.total_cost = orderedItem.totalCost()
    if (orderedItem.isReady()) this.hardwareOrder.ordered_items.push(orderedItem)
    return this.selectNewItem()
  }

  removeItem(orderedItem) {
    this.hardwareOrder.removeItem(orderedItem)
    return this.clearRequiredCarrierId()
  }
}

angular.module('argon.hardwareOrdering').service('$hwStore', Store)

Store.$inject = ['$hwHardwareOrder', '$hwSearchFilters', '$timeout']
