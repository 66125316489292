import angular from 'angular'
import { OrderItem } from '../models/order_item'
import { Order } from '../models/order'
import { addCustomerOneTimeUseLocation, getCustomerOneTimeUseLocations } from './orders_helpers'

angular.module('ArgonModule').controller('OrdersCtrl', [
  '$scope',
  '$element',
  function ($scope, $element) {
    $scope.order = new Order()

    $scope.form = {}
    $scope.form.action = null

    $scope.initLocations = (availableLocations = []) => {
      $scope.available_locations = [...availableLocations, ...getCustomerOneTimeUseLocations()]
    }

    // When selecting one of One Time Use addresses we should show correct form
    $scope.setOneTimeUseLocationForm = () => {
      if (!$scope.oneTimeUseLocationIsSelected()) return
      $scope.requiredCustomLocationForm = !!$scope.order.location.bell_api_city_id
    }

    const $formEl = $element.find('form')
    $formEl.submit(() => {
      if ($formEl.is('.ng-invalid')) return
      if (!$scope.oneTimeUseLocationIsSelected()) return
      // save new One Time Use location
      addCustomerOneTimeUseLocation($scope.order.location)
    })

    // by ID
    $scope.orderItems = {}

    $scope.initOrderItem = (item) => ($scope.orderItems[item.id] = new OrderItem(item))

    // Because we should not change default contacts.
    $scope.contactsCopy = () =>
      $scope.contacts.slice(0).map((contact) => angular.extend({}, contact))

    // Location doesn't contain country. Country is needed for fullAddress
    $scope.initCountry = (country) => ($scope.locationCountry = country)
    $scope.fullAddress = () => {
      // Computed fullAddress with empty elements filtered out
      return [
        $scope.location.address1,
        $scope.location.city,
        $scope.location.province_code,
        $scope.location.postal_code,
        $scope.locationCountry,
      ]
        .filter(Boolean)
        .join(', ')
    }

    // so each toggle will reset useless attributes automatically
    // I will send blank attrs on form submit because fields still exsists
    // (I don't use ng-id, I use ng-show)
    let customLocation = { name: 'New Address' }
    let foundLocation = { name: 'New Address' }

    // don't forget to check existing data
    $scope.requiredCustomLocationForm = false
    $scope.initLocation = (attrs) => {
      $scope.order.location = attrs

      if ($scope.oneTimeUseLocationIsSelected()) {
        if ($scope.order.location.bell_api_city_id) {
          $scope.requiredCustomLocationForm = true
          customLocation = $scope.order.location
        } else {
          $scope.requiredCustomLocationForm = false
          foundLocation = $scope.order.location
        }
      }
    }

    $scope.toggleRequiredCustomLocationForm = () => {
      if ($scope.requiredCustomLocationForm) {
        $scope.requiredCustomLocationForm = false

        customLocation = $scope.order.location
        $scope.order.location = foundLocation
      } else {
        $scope.requiredCustomLocationForm = true

        foundLocation = $scope.order.location
        $scope.order.location = customLocation
      }
    }

    $scope.oneTimeUseLocationIsSelected = () =>
      $scope.order.location
        ? $scope.order.location.is_one_time_use || $scope.order.location.name == 'New Address'
        : false

    $scope.customLocationIsSelected = () =>
      $scope.oneTimeUseLocationIsSelected() && $scope.requiredCustomLocationForm

    return this
  },
])
