import angular from 'angular'
import { customer_orderable_products_path } from '~/helpers/rails-routes'

function controller($scope, $attrs, $searchFilters) {
  const ctrl = this

  ctrl.filters = $searchFilters
  ctrl.urlForProducts = null
  ctrl.customProduct = {}

  const filtersWatcher = () => {
    if (ctrl.filters.isProductsMode()) {
      ctrl.loadProducts()
    } else {
      // hide template
      ctrl.urlForProducts = null
    }
  }

  $scope.ctrl = ctrl
  $scope.$watch('ctrl.filters', filtersWatcher, true)

  $scope.$on('$includeContentRequested', () => (ctrl.loaded = false))
  $scope.$on('$includeContentLoaded', () => (ctrl.loaded = true))

  const urlForProducts = () => {
    if (!ctrl.filters.customer_id) return

    const options = {
      customer_id: ctrl.filters.customer_id,
      carrier_id: ctrl.filters.carrier_id,
      category: ctrl.filters.products_category,
      permitted: ctrl.filters.permitted,
      order_id: $attrs.orderId,
      page: 1,
      per_page: 16,
    }

    // blank value for all device makes
    if (ctrl.filters.device_make_id) options.device_make_id = ctrl.filters.device_make_id

    return customer_orderable_products_path(options)
  }

  // $event needed for pagination
  // angular will render template by url
  const loadProducts = ($event = null) => {
    if ($event) $event.preventDefault()
    const customUrl = $event?.target.href

    return (ctrl.urlForProducts = customUrl || urlForProducts())
  }

  const newProduct = () => {
    const $option = $(`form#new_custom_product option[value=${ctrl.customProduct.deviceModelId}]`)
    const $optgroup = $option.parent('optgroup')

    const attrs = jQuery.extend({}, ctrl.customProduct)
    // clear form
    ctrl.customProduct = {}

    const term = {
      name: attrs.termName,
      price: attrs.termPrice,
      description: `$${attrs.termPrice} - ${attrs.termName}`,
    }

    const product = {
      name: $option.text(),
      plans: [term],
      device_model_id: attrs.deviceModelId,
      device_make_id: $optgroup.attr('value'),
      device_make_name: $optgroup.attr('label'),
      carrier_ids: [ctrl.filters.carrier_id],
    }

    if (attrs.color) product.colors = [attrs.color]

    return product
  }

  ctrl.loadProducts = loadProducts
  ctrl.newProduct = newProduct

  return ctrl
}

controller.$inject = ['$scope', '$attrs', '$hwSearchFilters']

angular.module('argon.hardwareOrdering').controller('hwOrderableProductsCtrl', controller)
