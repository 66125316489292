import angular from 'angular'

// attention! values depends on hardware_orders_controller.rb#default_mode
class SearchFilters {
  constructor() {
    this.customer_id = null
    this.carrier_id = null
    this.device_make_id = null
    this.mode = null
    this.tab = null
    this.products_category = null
    // used for permitted/all products switcher
    this.permitted = '1'
  }

  enableInventoryDevicesMode() {
    return (this.mode = 'inventory_devices')
  }

  disableInventoryDevicesMode() {
    return (this.mode = 'products')
  }

  isInventoryDevicesMode() {
    return this.mode == 'inventory_devices'
  }

  enableSuspendedLinesMode() {
    return (this.mode = 'suspended_lines')
  }

  enableDevicesMode() {
    return (this.mode = 'devices')
  }

  isSuspendedLinesMode() {
    return this.mode == 'suspended_lines'
  }

  isDevicesMode() {
    return this.mode == 'devices'
  }

  isProductsMode() {
    return this.mode == 'products'
  }

  enableProductsMode() {
    return (this.mode = 'products')
  }

  isAccessoriesMode() {
    return this.mode == 'accessories'
  }

  enableAccessoriesMode() {
    return (this.mode = 'accessories')
  }

  enableNewDeviceOrderTab() {
    if (this.isNewDeviceOrderTab()) return
    this.tab = 'new'
    return this.enableProductsMode()
  }

  isNewDeviceOrderTab() {
    return this.tab == 'new'
  }

  isHardwareUpgradeTab() {
    return this.tab == 'replacement'
  }

  enableHardwareUpgradeTab() {
    if (this.isHardwareUpgradeTab()) return
    this.tab = 'replacement'
    return this.enableDevicesMode()
  }

  isAccessoriesTab() {
    return this.tab == 'accessory'
  }

  enableAccessoriesTab() {
    if (this.isAccessoriesTab()) return
    this.tab = 'accessory'
    return this.enableAccessoriesMode()
  }

  clear() {
    return (this.device_make_id = null)
  }
}

angular.module('argon.hardwareOrdering').service('$hwSearchFilters', SearchFilters)
